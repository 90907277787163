<template>
  <main>
    <b-overlay :show="loading">
      <validation-observer ref="form">
        <b-row>
          <b-col sm="12" md="12" lg="4" xl="4">
            <b-card-actions :title="pb.no_invoice" action-collapse>
              <b-card-title>
                <b-form-group v-if="!isInvoiceAvailable">
                  <label for="">Pilih No.Invoice</label>
                  <v-select v-model="selectedInvoice" :reduce="(option) => option.value" :options="invoiceList"
                    label="text" />
                </b-form-group>
                <!-- <p class="mb-0" v-else>
                    <strong>No. Invoice: {{ pb.no_invoice }}</strong>
                  </p> -->
              </b-card-title>
              <b-row>
                <!-- <b-col sm="12" md="12" lg="12" xl="12">
                  <b-form-group v-if="!isInvoiceAvailable">
                    <label for="">Pilih No.Invoice</label>
                    <v-select v-model="selectedInvoice" :reduce="(option) => option.value" :options="invoiceList"
                      label="text" />
                  </b-form-group>
                  <p class="mb-0" v-else>
                    <strong>No. Invoice: {{ pb.no_invoice }}</strong>
                  </p>
                </b-col> -->
                <b-col sm="12" md="12" lg="12" xl="12">
                  <b-button id="popover-button-3" variant="outline-primary" block @click="show = !show">
                    Petunjuk
                  </b-button>

                  <b-popover :show.sync="show" target="popover-button-3" placement="top" triggers="click">
                    <template #title>
                      Petunjuk
                    </template>

                    <ol>
                      <li>Pilih salah satu barang</li>
                      <li>Tunggu beberapa detik, karena sistem mengecek barang tersebut sudah mempunyai penyimpanan atau
                        belum</li>
                      <li>Jika punya penyimpanan, admin langsung ceklis salah satu penyimpanan barang tersebut</li>
                      <li>Lalu isi jumlah stok</li>

                      <li>Tapi jika barang tersebut belum mempunyai penyimpanan, maka harus buat penyimpanan terlebih
                        dahulu</li>
                      <!-- <li>Vestibulum laoreet porttitor sem</li> -->
                    </ol>
                  </b-popover>
                </b-col>
                <!-- <b-col class="mt-1" sm="12" md="12" lg="12" xl="12">
                  <b-alert variant="warning" show>
                    <h4 class="alert-heading">
                      Petunjuk
                    </h4>
                    <div class="alert-body">
                      <ol>
                        <li>Pilih salah satu barang</li>
                        <li>Tunggu beberapa detik, karena sistem mengecek barang tersebut sudah mempunyai penyimpanan atau
                          belum</li>
                        <li>Jika punya penyimpanan, admin langsung ceklis salah satu penyimpanan barang tersebut</li>
                        <li>Lalu isi jumlah stok</li>

                        <li>Tapi jika barang tersebut belum mempunyai penyimpanan, maka harus buat penyimpanan terlebih
                          dahulu</li>
                        <li>Vestibulum laoreet porttitor sem</li>
                      </ol>
                    </div>
                  </b-alert>
                </b-col> -->
              </b-row>
            </b-card-actions>
          </b-col>
          <!-- List barang di penerimaan -->
          <b-col sm="12" md="12" lg="8" xl="8">
            <b-card title="Pilih Barang">
              <b-table responsive striped bordered :fields="fields" :items="barangs">
                <template #cell(action)="{ item }">
                  <b-form-radio v-model="selectedBarang" :value="item" :name="`barang`" />
                </template>
                <template #cell(barang)="{ item }">
                  {{ item.barang ? item.barang.nama : "-" }}
                </template>
                <template #cell(varian)="{ item }">
                  {{ item.barang ? item.barang.varian : "-" }}
                </template>
                <template #cell(satuan)="{ item }">
                  {{
                    item.barang && item.barang.satuan
                    ? item.barang.satuan.satuan
                    : "-"
                  }}
                </template>
              </b-table>
            </b-card>
          </b-col>
          <!-- Penempatan gudang -->
          <b-col sm="12" md="12">
            <b-card>
              <b-form>
                <b-row>
                  <b-col sm="4" md="4" lg="4" xl="4">
                    <b-form-group label="Buat Penyimpanan Baru untuk barang ini?">
                      <b-form-radio v-model="mode" name="some-radios" value="2">Buat Penyimpanan baru</b-form-radio>
                      <b-form-radio v-model="mode" name="some-radios" value="1">Pilih penyimpanan yang sudah
                        ada</b-form-radio>
                    </b-form-group>
                  </b-col>
                </b-row>
                <template v-if="mode == 2">
                  <b-row>
                    <!-- <b-col sm="4" md="4" lg="4" xl="4">
                      <b-form-group>
                        <validation-provider #default="{ errors }" name="gudang" rules="required">
                          <label for="">Pilih Gudang</label>
                          <v-select :options="gudangs" v-model="form.gudang_id" :required="!form.gudang_id"
                            :reduce="(option) => option.value" label="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->
                    <b-col sm="12" md="6" lg="3" xl="3">
                      <b-form-group>
                        <validation-provider #default="{ errors }" name="blok" rules="required">
                          <label for="">Pilih Blok</label>
                          <b-input-group>
                            <!-- <v-select :options="bloks" v-model="form.blok_id" :required="!form.blok_id"
                            :reduce="(option) => option.value" label="text" /> -->
                            <b-form-select :options="bloks" v-model="form.blok_id" :required="!form.blok_id"
                              :reduce="(option) => option.value" label="text" @change="getPalet()"/>
                            <b-input-group-append>
                              <b-button variant="outline-success" @click="showModalBlok = true"><feather-icon
                                  icon="PlusIcon" /></b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-modal v-model="showModalBlok" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal"
                        centered title="Form Tambah">
                        <b-form>
                          <b-form-group>
                            <label for="blok">Nama Blok</label>
                            <b-form-input v-model="formblok.blok" placeholder="Nama blok" />
                          </b-form-group>
                        </b-form>

                        <template #modal-footer>
                          <section class="d-flex justify-content-end align-items-center">
                            <b-button size="sm" class="mr-1" variant="success" @click="submitblok">
                              Tambah
                            </b-button>
                            <b-button size="sm" variant="danger" @click="showModalBlok = false">
                              Keluar
                            </b-button>
                          </section>
                        </template>
                      </b-modal>
                    </b-col>
                    <b-col sm="12" md="6" lg="3" xl="3">
                      <b-form-group>
                        <label for="">Pilih Palet</label>
                        <b-input-group>
                          <!-- <v-select :options="palets" v-model="form.palet_id" :required="!form.palet_id"
                          :reduce="(option) => option.value" label="text" /> -->
                          <b-form-select :options="palets" v-model="form.palet_id" :required="!form.palet_id"
                            :reduce="(option) => option.value" label="text" @change="getRak()"/>
                          <b-input-group-append>
                            <b-button variant="outline-success" @click="showModalPalet = true"><feather-icon
                                icon="PlusIcon" /></b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <b-modal v-model="showModalPalet" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal"
                        centered title="Form Tambah">
                        <b-form>
                          <b-form-group>
                            <label for="blok">Blok</label>
                            <b-form-select v-model="form.blok_id" :options="bloks" label="Blok" />
                          </b-form-group>
                          <b-form-group>
                            <label for="pallet">Nama Palet</label>
                            <b-form-input v-model="formpalet.palet" id="pallet" placeholder="Nama Palet" />
                          </b-form-group>
                        </b-form>

                        <template #modal-footer>
                          <section class="d-flex justify-content-end align-items-center">
                            <b-button size="sm" class="mr-1" variant="success" @click="submitpalet">
                              Tambah
                            </b-button>
                            <b-button size="sm" variant="danger" @click="showModalPalet = false">
                              Keluar
                            </b-button>
                          </section>
                        </template>
                      </b-modal>
                    </b-col>
                    <b-col sm="12" md="6" lg="3" xl="3">
                      <b-form-group>
                        <label for="">Pilih Rak (optional)</label>
                        <!-- <b-button variant="info">Button</b-button> -->
                        <!-- <v-select v-model="form.rak_id" :options="raks" :reduce="(option) => option.value" label="text"
                          /> --><b-input-group>
                          <b-form-select v-model="form.rak_id" :options="raks" :reduce="(option) => option.value"
                            label="text" @change="getLaci()" />
                          <b-input-group-append>
                            <b-button variant="outline-success" @click="showModalRak = true"><feather-icon
                                icon="PlusIcon" /></b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <b-modal v-model="showModalRak" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal"
                        centered title="Form Tambah">
                        <b-form>
                          <b-form-group>
                            <label for="blok">Blok</label>
                            <b-form-select v-model="form.blok_id" :options="bloks" label="Blok" />
                          </b-form-group>
                          <b-form-group>
                            <label for="palet">Palet</label>
                            <b-form-select v-model="form.palet_id" :options="palets" label="Palet" />
                          </b-form-group>
                          <b-form-group>
                            <label for="rak">Nama Rak</label>
                            <b-form-input v-model="formrak.rak" id="rak" placeholder="Nama Rak" />
                          </b-form-group>
                        </b-form>

                        <template #modal-footer>
                          <section class="d-flex justify-content-end align-items-center">
                            <b-button size="sm" class="mr-1" variant="success" @click="submitrak">
                              Tambah
                            </b-button>
                            <b-button size="sm" variant="danger" @click="showModalRak = false">
                              Keluar
                            </b-button>
                          </section>
                        </template>
                      </b-modal>
                    </b-col>
                    <b-col sm="12" md="6" lg="3" xl="3">
                      <b-form-group>
                        <label for="">Pilih Laci (optional)</label>
                        <!-- <v-select v-model="form.laci_id" :options="lacis" :reduce="(option) => option.value" label="text"
                          /> --><b-input-group>
                          <b-form-select v-model="form.laci_id" :options="lacis" :reduce="(option) => option.value"
                            label="text" />
                          <b-input-group-append>
                            <b-button variant="outline-success" @click="showModalLaci = true"><feather-icon
                                icon="PlusIcon" /></b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <b-modal v-model="showModalLaci" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal"
                        centered title="Form Tambah">
                        <b-form>
                          <b-form-group>
                            <label for="blok">Blok</label>
                            <b-form-select v-model="form.blok_id" :options="bloks" label="Blok" />
                          </b-form-group>
                          <b-form-group>
                            <label for="palet">Palet</label>
                            <b-form-select v-model="form.palet_id" :options="palets" label="Palet" />
                          </b-form-group>
                          <b-form-group>
                            <label for="rak">Rak</label>
                            <b-form-select v-model="form.rak_id" :options="raks" label="Rak" />
                          </b-form-group>
                          <b-form-group>
                            <label for="laci">Nama Laci</label>
                            <b-form-input v-model="formlaci.laci" id="laci" placeholder="Nama Laci" />
                          </b-form-group>
                        </b-form>

                        <template #modal-footer>
                          <section class="d-flex justify-content-end align-items-center">
                            <b-button size="sm" class="mr-1" variant="success" @click="submitlaci">
                              Tambah
                            </b-button>
                            <b-button size="sm" variant="danger" @click="showModalLaci = false">
                              Keluar
                            </b-button>
                          </section>
                        </template>
                      </b-modal>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" md="12" lg="4" xl="4" v-if="optkonversi.length > 0">
                      <b-form-group>
                        <label for="nilai">Jumlah</label>
                        <b-form-input v-model="nilai" type="number" id="nilai" placeholder="nilai" />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4" xl="4" v-if="optkonversi.length > 0">
                      <b-form-group>
                        <label for="konversi">Konversi</label>
                        <b-form-select v-model="id_satuan" :reduce="(option) => option.value" :options="optkonversi"
                          id="konversi" placeholder="konversi" />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4" xl="4">
                      <b-form-group>
                        <label for="jumlah" v-if="optkonversi.length > 0">Jumlah Hasil Konversi</label>
                        <label for="jumlah" v-if="optkonversi == null || 0 || []">Jumlah</label>
                        <b-input-group :append="selectedBarang ? selectedBarang.barang.satuan.satuan : '-'">
                          <!-- prepend="$" -->
                          <b-form-input v-model="form.jumlah" type="number" id="jumlah" placeholder="Jumlah" />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group>
                    <b-button @click.prevent="addBarang" variant="primary">Tambah</b-button>
                  </b-form-group>
                </template>
                <template v-if="mode == 1">
                  <b-row>
                    <b-col sm="12" md="12" lg="12">
                      <b-table v-if="loaded" striped small hover responsive :items="items"
                        :fields="fieldsPenyimpananBarangs">
                        <!-- :sort-by.sync="sortByBarangs" :sort-desc.sync="sortDescBarangs"
                    :sort-direction="sortDirectionBarangs" :filter-included-fields="filterOnBarangs" :per-page="perPageBarangs" 
                    @filtered="onFilteredBarangs" -->
                        <template #cell(penyimpanan)="data">
                          <!-- <b-form-checkbox @change="selectAll" v-model="selected" /> -->
                          <b-form-checkbox :value="data.item" v-model="pilihpenyimpanan" />
                        </template>
                        <template #cell(no)="row">
                          <strong class="text-center">
                            {{ row.index + 1 }}
                          </strong>
                        </template>
                        <template #cell(kode)="{ item }">
                          {{ item.barang ? item.barang.kode : '-' }}
                        </template>
                        <template #cell(nama)="{ item }">
                          {{ item.barang ? item.barang.nama : '-' }}
                        </template>
                        <template #cell(blok)="{ item }">
                          {{ item.blok ? item.blok.blok : '-' }}
                        </template>
                        <template #cell(palet)="{ item }">
                          {{ item.palet ? item.palet.palet : '-' }}
                        </template>
                        <template #cell(rak)="{ item }">
                          {{ item.rak ? item.rak.rak : '-' }}
                        </template>
                        <template #cell(laci)="{ item }">
                          {{ item.laci ? item.laci.laci : '-' }}
                        </template>
                        <template #cell(stok)="{ item }">
                          {{ item.stok ? item.stok : 0 }}
                        </template>
                        <template #cell(satuan)="{ item }">
                          {{ item.barang ? item.barang.satuan.satuan : item.barang_id }}
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" md="12" lg="4" xl="4" v-if="optkonversi.length > 0">
                      <b-form-group>
                        <label for="nilai">Jumlah</label>
                        <b-form-input v-model="nilai" type="number" id="nilai" placeholder="nilai" />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4" xl="4" v-if="optkonversi.length > 0">
                      <b-form-group>
                        <label for="konversi">Konversi</label>
                        <b-form-select v-model="id_satuan" :reduce="(option) => option.value" :options="optkonversi"
                          id="konversi" placeholder="konversi" />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4" xl="4">
                      <b-form-group>
                        <label for="jumlah" v-if="optkonversi.length > 0">Jumlah Hasil Konversi</label>
                        <label for="jumlah" v-if="optkonversi.length == 0">Jumlah</label>
                        <b-input-group :append="selectedBarang ? selectedBarang.barang.satuan.satuan : '-'">
                          <!-- prepend="$" -->
                          <b-form-input v-model="form.jumlah" type="number" id="jumlah" placeholder="Jumlah" />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group>
                    <b-button @click.prevent="addBarang" variant="primary">Tambah</b-button>
                  </b-form-group>
                </template>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
        <!-- <b-card>
  {{ this.pilihpenyimpanan }}
</b-card> -->
        <b-row>
          <!-- Barang yang sudah disimpan -->
          <b-col cols="12">
            <b-card>
              <b-table :fields="fieldsAddedItems" bordered striped responsive :items="addedItems">
                <template #cell(no)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(lokasi)="{ item }">
                  <strong>
                    {{ item.lokasi }}
                  </strong>
                </template>
                <template #cell(action)="row">
                  <b-link class="text-danger" @click.prevent="addedItems.splice(row.index, 1)">
                    <feather-icon size="24" icon="XCircleIcon"></feather-icon>
                  </b-link>
                </template>
              </b-table>
              <div class="d-flex justify-content-end">
                <b-button variant="primary" @click.prevent="submit">
                  Simpan
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </main>
</template>
<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTable,
  BOverlay,
  BFormSelect,
  BFormRadio,
  BLink,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BFormCheckbox,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BAlert,
  VBPopover,
  BPopover,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required",
});
extend("positive", (value) => {
  return value > 0;
});

export default {
  components: {
    BCardTitle,
    BCardActions,
    BInputGroup,
    BAlert,
    vSelect,
    BFormSelect,
    BLink,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    BFormGroup,
    BFormRadio,
    BTable,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BButton,
    BFormInput,
    VBPopover,
    BPopover,
    BInputGroupAppend,
    BModal,
    BForm,
  },
  watch: {
    "selectedBarang"(barang_id) {
      if (barang_id != null) {
        this.getKonversi(barang_id);
        this.getStockBarang(barang_id)
      } else {
        return
      }
      // this.barang_id = item.barang_id.id
      // this.namabarang = item.barang_id.nama
      // this.jumlah = item.jumlah
      // this.sementara = item.id
    },

    nilai(value2) {
      let hasil = value2 * this.id_satuan;
      this.form.jumlah = hasil;
    },
    id_satuan(value) {
      let hasil = value * this.nilai;
      this.form.jumlah = hasil;
    },
    "form.gudang_id"() {
      this.getBlok();
    },
    // "form.blok_id"() {
    //   this.palets = []
    //   this.raks = []
    //   this.lacis = []
    //   this.getPalet();
    // },
    // "form.palet_id"() {
    //   this.raks = []
    //   this.lacis = []
    //   this.getRak();
    // },
    // "form.rak_id"() {
    //   this.lacis = []
    //   this.getLaci();
    // },
    selectedInvoice(penerimaan_id) {
      const penerimaan =
        this.$store.getters["penerimaan-barang/getData"](penerimaan_id);
      if (!penerimaan) {
        this.displayError({
          text: "Data Penerimaan tidak ditemukan, harap pilih yang lain",
        });
        return false;
      }

      this.pb = penerimaan;
      this.form.penerimaan_id = this.pb.id;
      this.params = {
        penerimaan_id,
      };
      this.getBarangPenerimaan();
    },
  },
  data: () => ({
    loaded: true,
    mode: 1,
    params: {},
    loading: false,
    selectedInvoice: null,
    selectedBarang: null,
    addedItems: [],
    fieldsAddedItems: [
      { key: "no", label: "No" },
      { key: "barang", label: "Barang" },
      { key: "varian", label: "Varian" },
      { key: "jumlah", label: "Jumlah" },
      { key: "lokasi", label: "Lokasi" },
      { key: "action", label: "#" },
    ],
    fields: [
      { key: "action", label: "#" },
      { key: "barang", label: "Barang" },
      { key: "varian", label: "Varian" },
      { key: "sisa", label: "Sisa" },
      { key: "satuan", label: "Satuan" },
    ],
    bloks: [],
    pb: {
      id: null,
      no_invoice: null,
    },
    raks: [],
    lacis: [],
    palets: [],
    gudangs: [],
    barangs: [],
    form: {
      penerimaan_id: null,
      gudang_id: null,
      blok_id: null,
      palet_id: null,
      rak_id: null,
      laci_id: null,
    },
    optkonversi: [],
    invoiceList: [],
    id_satuan: 0,
    hasil: 0,
    nilai: 0,
    //Penyimpanan
    pilihpenyimpanan: null,
    fieldsPenyimpananBarangs: [
      { key: "penyimpanan", label: "Pilih" },
      { key: "no", label: "No", sortable: true },
      { key: "kode", label: "Kode" },
      { key: "nama", label: "Nama Barang" },
      { key: "blok", label: "blok" },
      { key: "palet", label: "palet" },
      { key: "rak", label: "rak" },
      { key: "laci", label: "laci" },
      { key: "stok", label: "stok" },
      { key: "satuan", label: "Satuan" },
    ],
    items: null,
    show: false,
    directives: {
      'b-popover': VBPopover,
    },
    showModalBlok: false,
    formblok: {
      blok: null,
    },
    showModalPalet: false,
    formpalet: {
      blok: null,
      palet: null,
    },
    showModalRak: false,
    formrak: {
      blok: null,
      palet: null,
      rak: null,
    },
    showModalLaci: false,
    formlaci: {
      blok: null,
      palet: null,
      rak: null,
      laci: null
    },
  }),
  methods: {
    submitblok() {
      if (this.formblok.blok == null || this.formblok.blok == '') {
        this.displayWarning({
          text: "Blok harap diisi !",
        });
        return false
      }
      if (this.id) {
        this.formblok.id = this.id
      }
      const payload = {
        blok: this.formblok.blok,
        gudang_id: this.myGudang.id
      }
      this.$store.dispatch('blok/save', [payload])
        .then(() => {
          this.getBlok()
          this.displaySuccess({
            text: "Blok berhasil ditambahkan !",
          });
          this.showModalBlok = false
          this.formblok.blok = null
        })
        .catch(e => console.error(e))
    },
    submitpalet() {
      if (this.formpalet.palet == null || this.formpalet.palet == '') {
        this.displayWarning({
          text: "Palet harap diisi !",
        });
        return false
      }
      if (this.form.blok_id == null || this.form.blok_id == '') {
        this.displayWarning({
          text: "Blok harap dipilih !",
        });
        return false
      }
      if (this.id) {
        this.formpalet.id = this.id
      }
      const payload = {
        blok_id: this.form.blok_id,
        palet: this.formpalet.palet,
        gudang_id: this.myGudang.id
      }
      this.$store.dispatch('pallet/save', [payload])
        .then(() => {
          this.getPalet()
          this.displaySuccess({
            text: "Palet berhasil ditambahkan !",
          });
          this.showModalpalet = false
          this.formpalet.palet = null
        })
        .catch(e => console.error(e))
    },
    submitrak() {
      if (this.formrak.rak == null || this.formrak.rak == '') {
        this.displayWarning({
          text: "Rak harap diisi !",
        });
        return false
      }
      if (this.form.blok_id == null || this.form.blok_id == '') {
        this.displayWarning({
          text: "Blok harap dipilih !",
        });
        return false
      }
      if (this.form.palet_id == null || this.form.palet_id == '') {
        this.displayWarning({
          text: "Palet harap dipilih !",
        });
        return false
      }
      if (this.id) {
        this.formrak.id = this.id
      }
      const payload = {
        blok_id: this.form.blok_id,
        palet_id: this.form.palet_id,
        rak: this.formrak.rak,
        gudang_id: this.myGudang.id
      }
      this.$store.dispatch('rak/save', [payload])
        .then(() => {
          this.getRak()
          this.displaySuccess({
            text: "Rak berhasil ditambahkan !",
          });
          this.showModalrak = false
          this.formrak.rak = null
        })
        .catch(e => console.error(e))
    },
    submitlaci() {
      if (this.formlaci.laci == null || this.formlaci.laci == '') {
        this.displayWarning({
          text: "Laci harap diisi !",
        });
        return false
      }
      if (this.form.blok_id == null || this.form.blok_id == '') {
        this.displayWarning({
          text: "Blok harap dipilih !",
        });
        return false
      }
      if (this.form.palet_id == null || this.form.palet_id == '') {
        this.displayWarning({
          text: "Palet harap dipilih !",
        });
        return false
      }
      if (this.form.rak_id == null || this.form.rak_id == '') {
        this.displayWarning({
          text: "Rak harap dipilih !",
        });
        return false
      }
      if (this.id) {
        this.formlaci.id = this.id
      }
      const payload = {
        blok_id: this.form.blok_id,
        palet_id: this.form.palet_id,
        rak_id: this.form.rak_id,
        laci: this.formlaci.laci,
        gudang_id: this.myGudang.id
      }
      this.$store.dispatch('laci/save', [payload])
        .then(() => {
          this.getLaci()
          this.displaySuccess({
            text: "Laci berhasil ditambahkan !",
          });
          this.showModallaci = false
          this.formlaci.laci = null
        })
        .catch(e => console.error(e))
    },
    async getKonversi(barang_id) {
      const optkonversi = await this.$store.dispatch("konversi/getData", {
        id_barang: barang_id.barang.id,
      });
      this.optkonversi = [];
      optkonversi.forEach((konv) => {
        this.optkonversi.push({
          value: konv.nilai,
          text: konv.satuan.satuan,
        });
      });
    },
    submit() {
      this.addedItems.map((item) => {
        delete item.barang;
        delete item.lokasi;
      });
      let payload = [];
      Object.assign(payload, this.addedItems);

      this.loading = true;
      this.$store
        .dispatch("penyimpanan/save", payload)
        .then(() => {
          this.loading = false;
          this.displaySuccess({
            text: "Stok Barang berhasil ditambahkan!",
          });

          setTimeout(() => {
            this.$router.push("/stock-in");
          }, 1000);
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    addBarang() {
      if (this.form.jumlah > this.selectedBarang.jumlah) {
        this.$bvToast.toast(
          "Jumlah yang diisi tidak boleh lebih dari Sisa jumlah barang",
          {
            title: `Peringatan`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          }
        );
        // this.displayError({
        //     text: 'Jumlah yang disimpan harus kurang dari atau sama dengan sisa stok penerimaan'
        // })
        return false;
      } else if (this.form.jumlah == null || 0) {
        this.$bvToast.toast(
          "Jumlah yang diisi tidak boleh 0 (nol)",
          {
            title: `Peringatan`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          }
        );
        return false;
      }
      this.$refs.form.validate().then((success) => {
        if (success) {
          let data = {};
          Object.assign(data, this.form);
          if (this.mode == 2) {
            data.barang =
              this.selectedBarang && this.selectedBarang.barang
                ? this.selectedBarang.barang.nama
                : null;
            data.barang_id =
              this.selectedBarang && this.selectedBarang.barang
                ? this.selectedBarang.barang.id
                : null;
            data.varian =
              this.selectedBarang && this.selectedBarang.barang
                ? this.selectedBarang.barang.varian
                : null;
            data.gudang_id = this.myGudang.id

            // get lokasi
            const blok = this.bloks.find((blok) => blok.value == data.blok_id);
            const palet = data.palet_id
              ? this.palets.find((palet) => palet.value == data.palet_id)
              : null;
            const rak = data.rak_id
              ? this.raks.find((rak) => rak.value == data.rak_id)
              : null;
            const laci = data.laci_id
              ? this.lacis.find((laci) => laci.value == data.laci_id)
              : null;

            let lokasi = blok ? `Blok: ${blok.text}` : "";
            if (palet) {
              lokasi += `, Palet: ${palet.text}`;
            }
            if (rak) {
              lokasi += `, Rak: ${rak.text}`;
            }

            if (laci) {
              lokasi += `, Laci: ${laci.text}`;
            }
            data.lokasi = lokasi;
            this.addedItems.push(data);
            (this.form.blok_id = null),
              (this.form.palet_id = null),
              (this.form.rak_id = null),
              (this.form.laci_id = null);
          } else {

            data.barang =
              this.selectedBarang && this.selectedBarang.barang
                ? this.selectedBarang.barang.nama
                : null;
            data.barang_id =
              this.selectedBarang && this.selectedBarang.barang
                ? this.selectedBarang.barang.id
                : null;

            // get lokasi
            data.blok_id = this.pilihpenyimpanan.blok ? this.pilihpenyimpanan.blok.id : null;
            data.palet_id = this.pilihpenyimpanan.palet ? this.pilihpenyimpanan.palet.id : null;
            data.rak_id = this.pilihpenyimpanan.rak ? this.pilihpenyimpanan.rak.id : null;
            data.laci_id = this.pilihpenyimpanan.laci ? this.pilihpenyimpanan.laci.id : null;

            data.blok_idtext = this.pilihpenyimpanan.blok ? this.pilihpenyimpanan.blok.blok : null;
            data.palet_idtext = this.pilihpenyimpanan.palet ? this.pilihpenyimpanan.palet.palet : null;
            data.rak_idtext = this.pilihpenyimpanan.rak ? this.pilihpenyimpanan.rak.rak : null;
            data.laci_idtext = this.pilihpenyimpanan.laci ? this.pilihpenyimpanan.laci.laci : null;

            let lokasi = data.blok_id ? `Blok: ${data.blok_idtext}` : "";
            if (data.palet_id) {
              lokasi += `, Palet: ${data.palet_idtext}`;
            }
            if (data.rak_id) {
              lokasi += `, Rak: ${data.rak_idtext}`;
            }

            if (data.laci_id) {
              lokasi += `, Laci: ${data.laci_idtext}`;
            }
            data.lokasi = lokasi;
            this.addedItems.push(data);
            (this.form.blok_id = null),
              (this.form.palet_id = null),
              (this.form.rak_id = null),
              (this.form.laci_id = null);
          }
        }
      });
    },
    async getStockBarang(barang_id) {
      let params = {
        // barang_id: barangsId.barang_id,
        barang_id: barang_id.barang.id,
        gudang_id: this.myGudang.id
      }

      this.$store.dispatch('penyimpanan/getData', params)
        .then(stocks => {
          this.items = stocks
        })
        .catch(e => {
          this.displayError(e)
          return false
        })
    },
    getGudang() {
      this.gudangs = [];
      this.$store.dispatch("gudang/getData", { jenis: 1 }).then((gudangs) => {
        gudangs.forEach((gudang) => {
          if (this.isAdminGudang || this.isSPV) {
            const itsMyGudang = gudang.id == this.myGudang.id;
            if (itsMyGudang) {
              this.gudangs.push({
                value: gudang.id,
                text: gudang.nama_gudang,
              });
            }
          } else {
            this.gudangs.push({
              value: gudang.id,
              text: gudang.nama_gudang,
            });
          }
        });
      });
    },
    getBlok() {
      this.bloks = [];
      this.$store.dispatch("blok/getData", { gudang_id : this.myGudang.id }).then((bloks) => {
        bloks.forEach((blok) => {
          this.bloks.push({
            value: blok.id,
            text: blok.blok,
          });
        });
      });
    },
    getPalet() {
      this.palets = [];
      this.form.palet_id = null
      this.form.rak_id = null
      this.form.laci_id = null
      this.$store.dispatch("pallet/getData", { blok_id: this.form.blok_id ? this.form.blok_id : null, gudang_id: this.myGudang.id }).then((palets) => {
        palets.forEach((palet) => {
          this.palets.push({
            value: palet.id,
            text: palet.palet,
          });
        });
      });
    },
    getRak() {
      this.raks = [];
      this.form.rak_id = null
      this.form.laci_id = null
      this.$store.dispatch("rak/getData", { palet_id: this.form.palet_id ? this.form.palet_id : null , gudang_id: this.myGudang.id }).then((raks) => {
        raks.forEach((rak) => {
          this.raks.push({
            value: rak.id,
            text: rak.rak,
          });
        });
      });
    },
    getLaci() {
      this.lacis = [];
      this.form.laci_id = null
      this.$store.dispatch("laci/getData", { rak_id: this.form.rak_id ? this.form.rak_id : null, gudang_id: this.myGudang.id }).then((lacis) => {
        lacis.forEach((laci) => {
          this.lacis.push({
            value: laci.id,
            text: laci.laci,
          });
        });
      });
    },
    getBarangPenerimaan() {
      this.$store
        .dispatch("penerimaan-barang/getDataBarang", this.params)
        .then((barangs) => {
          this.barangs = barangs;
        })
        .catch((e) => this.displayError(e));
    },
    getInvoicePenerimaan() {
      let params = { where: { selesai: 0 } };
      if (this.isAdminGudang) {
        params.gudang_id = this.myGudang.id;
        this.form.gudang_id = this.myGudang.id;
      }
      this.loading = true;
      this.$store
        .dispatch("penerimaan-barang/getData", params)
        .then((penerimaanBarangs) => {
          this.loading = false;
          penerimaanBarangs.forEach((penerimaan) => {
            this.invoiceList.push({
              value: penerimaan.id,
              text: penerimaan.no_invoice,
            });
          });

          if (this.$route.query.inv) {
            this.selectedInvoice = this.$route.query.inv;
          }

          this.getGudang();
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
  },
  computed: {
    isInvoiceAvailable() {
      return this.$route.query.inv;
    },
  },
  created() {
    this.getInvoicePenerimaan();
    this.getBlok()
  },
};
</script>